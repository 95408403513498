import { useState } from 'react';
import dynamic from 'next/dynamic';

import { REVIEWS_LIST } from '@/widgets/layouts/main/constants/reviews';

import { Article } from '@/shared/components/article';
import { Carousel, CarouselContent, CarouselItem } from '@/shared/components/ui/carousel';

import { ReviewCard } from './review-card';

interface ReviewsProps {
  title?: string;
}

const Dialog = dynamic(() => import('@/shared/components/ui/dialog').then((components) => components.Dialog));
const DialogContent = dynamic(() =>
  import('@/shared/components/ui/dialog').then((components) => components.DialogContent),
);

export const Reviews = (props: ReviewsProps) => {
  const { title = 'Отзывы' } = props;

  const [open, setOpen] = useState(false);
  const [reviewText, setReviewText] = useState('');

  const onOpenFullReviewClick = (text: string) => {
    setReviewText(text);
    setOpen(!open);
  };

  return (
    <Article>
      <h2 className="mb-8 header-h2">{title}</h2>

      <Carousel
        opts={{
          align: 'center',
          loop: true,
        }}
      >
        <CarouselContent className="min-h-[400px]">
          {REVIEWS_LIST.map((review, index) => {
            return (
              <CarouselItem
                key={index}
                className="flex justify-center sm:basis-1/2 md:basis-[40%] lg:basis-[42%] xl:basis-1/3 2xl:basis-[28%]"
              >
                <ReviewCard
                  text={review.text}
                  author={review.author}
                  textClass="line-clamp-12"
                  openFullReview={onOpenFullReviewClick}
                  className="w-full sm:w-72"
                />
              </CarouselItem>
            );
          })}
        </CarouselContent>

        <div className="pointer-events-none absolute inset-y-0 left-0 hidden h-full bg-gradient-to-r from-background xss:block sm:w-1/4"></div>
        <div className="pointer-events-none absolute inset-y-0 -right-1 hidden h-full bg-gradient-to-l from-background xss:block sm:w-1/4"></div>
      </Carousel>

      <Dialog open={open} modal onOpenChange={setOpen}>
        <DialogContent className="max-h-[70%] max-w-[90%] overflow-y-auto rounded-md sm:max-w-[425px]">
          <p>{reviewText}</p>
        </DialogContent>
      </Dialog>
    </Article>
  );
};
