const buildSuffix = (url?: { query?: Record<string, string>; hash?: string }) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash }),
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  favicon_ico: '/favicon.ico',
  next_svg: '/next.svg',
  robots_txt: '/robots.txt',
  sitemap_0_xml: '/sitemap-0.xml',
  sitemap_xml: '/sitemap.xml',
  vercel_svg: '/vercel.svg',
} as const;

export type StaticPath = typeof staticPath;
