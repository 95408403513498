import Link from 'next/link';

import CheckMarkIcon from '@/shared/components/icons/checkmark-icon';
import { Button } from '@/shared/components/ui/button';

interface CardProps {
  header: string;
  price: number;
  description?: string;
  benefitsList?: Array<string>;
  isServicePrimary: boolean;
  link: string;
}

export const ServiceCard = (props: CardProps) => {
  const { header, price, description, benefitsList, isServicePrimary, link } = props;

  return (
    <div
      className={`relative flex w-full max-w-[400px] flex-col gap-4 overflow-hidden rounded-2xl p-4 text-black dark:text-white ${isServicePrimary ? 'border-2 border-brand-accent-normal  shadow-lg shadow-neutral-500 dark:border-brand-accent-light dark:shadow-neutral-600' : 'border'}`}
    >
      <div className="h-14 sm:h-20">
        <h3 className="text-base font-semibold leading-5">{header}</h3>

        {description && (
          <p
            className="mt-1 text-xs leading-4 text-black/70 dark:text-white"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>

      <div className="flex flex-row gap-1" style={{ opacity: 1, transform: 'none' }}>
        <span className="text-sm text-black dark:text-white">
          от&nbsp;
          <span className="text-2xl font-bold tracking-tight">{price}</span>&nbsp;&#8381;&nbsp;/&nbsp;занятие
        </span>
      </div>

      <Link href={link} aria-label={`Перейти на страницу услуги ${header}`}>
        <Button variant="cta" className="w-full" aria-label={`Перейти на страницу услуги ${header}`}>
          Подробнее
        </Button>
      </Link>

      {benefitsList && (
        <>
          <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />

          <ul className="flex flex-col gap-2 font-normal">
            {benefitsList.map((benefit, index) => {
              return (
                <li key={index} className="flex items-center gap-3 text-xs font-medium text-black dark:text-white">
                  <CheckMarkIcon className="h-5 w-5 shrink-0 rounded-full bg-brand-accent-light p-[2px] text-white dark:bg-brand-accent-normal" />
                  <span className="flex" dangerouslySetInnerHTML={{ __html: benefit }} />
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};
