import ThunderstormIcon from '@/shared/components/icons/thunderstorm-icon';

interface TextPlusesListProps {
  items?: Array<{ header: string; text: string }>;
}

export const TextPlusesList = (props: TextPlusesListProps) => {
  const { items } = props;

  return (
    <ul className="max-w-3xl space-y-4">
      {items?.map((listItem, index) => {
        return (
          <li key={index}>
            <h3 className="mb-2 flex items-center">
              <ThunderstormIcon className="mr-2 h-6 w-6 shrink-0 text-brand-accent-light sm:mr-4" />

              <span className="header-h3" dangerouslySetInnerHTML={{ __html: listItem.header }} />
            </h3>

            <p
              className="pl-8 text-sm text-muted-foreground sm:pl-10 lg:text-base"
              dangerouslySetInnerHTML={{ __html: listItem.text }}
            />
          </li>
        );
      })}
    </ul>
  );
};
