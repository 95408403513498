import { Article } from '@/shared/components/article';

import { ServiceCard } from './service-card';

interface ServicesProps {
  servicesList: Array<any>;
}

export const Services = (props: ServicesProps) => {
  const { servicesList } = props;

  return (
    <Article>
      <div className="mb-4 max-w-lg sm:mb-8">
        <h2 className="mb-2 header-h2">Услуги</h2>

        <p className="text-sm text-muted-foreground">
          Поможем вам подобрать удобный формат, чтобы выучить язык для работы&nbsp;или переезда
        </p>
      </div>

      <div className="mx-auto grid w-full justify-center gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {servicesList.map((service, index) => {
          return (
            <ServiceCard
              key={index}
              header={service.header}
              description={service.description}
              price={service.price}
              benefitsList={service.benefitsList}
              isServicePrimary={service.isServicePrimary}
              link={service.link}
            />
          );
        })}
      </div>
    </Article>
  );
};
